import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const OnPackClaims = ({ data={} }) => {

  const { modules, title, description=false, id } = mapPageData(data.page)

  const [modulesToRender, heroModule ] = useModules(modules, { getHeroModule: true })

  return (
  <>
  <SEO title={title} description={description}/>
  <Layout 
    staticMenuLayout={true}
    homeLink={true}
    staticMenu={true}
    heroModule={heroModule}
    className="static-page">
      {/* Content goes here */}
      { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}

      <PageNav 
        prev={{  text: "Typography", linkTo: "/typography"}} 
        next={{  text: "Packaging In Practice", linkTo: "/packaginginpractise"}}
      />
  </Layout>
  </>
)}

export default OnPackClaims


export const OnPackClaimsQuery = graphql`
  query OnPackClaimsQuery {
  page: contentfulPage(pageId: {eq: "on-pack-claims"}) {
      ...PageFragment
    }
  }
`
